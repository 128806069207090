@import url("https://fonts.googleapis.com/css?family=Roboto:300,400&display=swap");

.is-clipped {
  position: fixed;
  overflow: hidden !important;
  height: 100% !important;
}

body,
button,
input,
select,
textarea {
  font-family: "Roboto", sans-serif;
  font-weight: 300;
  font-size: 14px;
  line-height: 23px;
}

h1 {
  font-weight: 100;
  font-size: 64px;
  line-height: 104px;
}

h3 {
  font-weight: 100;
  font-size: 28px;
  line-height: 45px;
}

h4 {
  font-weight: 100;
  font-size: 18px;
}
